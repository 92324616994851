<template>
    <v-container fluid >
        <v-row no-gutters class="d-block">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="success" small v-if="permisos[2]" outlined @click="unidadOperativaNuevo()">
                                    <v-icon>add</v-icon>
                                    Nuevo
                                </v-btn>
                            </v-col>
                            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                                <v-btn color="info" small outlined @click="unidadesOperativasGet()">
                                    <v-icon>refresh</v-icon>
                                    Refrescar
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pt-0">
                                <v-text-field
                                    v-model="search"
                                    hide-details
                                    label="Buscar por Institución, Unidad Operativa y pulse en el botón."
                                    clearable>
                                    <template v-slot:append>
                                        <v-btn
                                            color="secondary" small outlined
                                            @click="unidadesOperativasBuscar()">
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </template>
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <!--v-data-table
                            dense
                            class="elevation-3"
                            :headers="tableHeaders"
                            :items="unidadesOperativas"
                            :loading="loading" :disabled="loading"
                            item-key="unidad_operativa_id"
                            fixed-header
                            height="65vh"
                            :hide-default-footer="true"
                            disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td class="text-left">{{ item.institucion }}</td>
                                    <td class="text-left">{{ item.unidad_operativa }}</td>
                                    <td class="text-left">{{ item.modo_formacion }}</td>
                                    <td class="text-right">{{ item.nota_minima_aprobar }}</td>
                                    <td class="text-right">{{ item.director_ejecutivo }}</td>
                                    <td class="text-right">{{ item.jefe_departamento }}</td>
                                    <td class="text-right">{{ item.plantilla_certificado }}</td>
                                    <td class="text-center">
                                        <v-btn color="info" small outlined @click="unidadesOperativasEdit(item)" title="Editar" v-if="permisos[3]">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    No existen registros.
                                </v-alert>
                            </template>
                        </v-data-table-->
                        <!-- el-table default-expand-all :data="tableData" style="width: 100%" row-key="unidad_operativa_id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"-->
                        <div class="table-container">
                        <el-table default-expand-all :data="unidadesOperativas" style="width: 2200px" row-key="unidad_operativa_id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                            <!--
                            <el-table-column prop="level" label="Level" width="180"></el-table-column>
                            <el-table-column prop="path" label="Path"></el-table-column>
                            <el-table-column prop="unidad_operativa_id" label="unidad_operativa_id" width="180"></el-table-column>
                            <el-table-column prop="unidad_operativa_padre_id" label="unidad_operativa_padre_id" width="180"></el-table-column>
                            -->
                            <el-table-column prop="unidad_operativa" label="Unidad Operativa" width="360"></el-table-column>
                            <el-table-column label="Actions" width="100">
                                <template v-slot="scope">
                                    <v-btn color="info" small outlined @click="unidadesOperativasEdit(scope.row)" title="Editar" v-if="permisos[3]">
                                    <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                            </el-table-column>
                            <el-table-column prop="institucion" label="Institucion" width="180"></el-table-column>
                            <el-table-column prop="modo_formacion" label="Modo Formacion" width="360"></el-table-column>
                            <el-table-column prop="nota_minima_aprobar" label="Nota Minima" width="120"></el-table-column>
                            <el-table-column prop="director_ejecutivo" label="Director Ejecutivo" width="360"></el-table-column>
                            <el-table-column prop="jefe_departamento" label="Jefe Departamento" width="360"></el-table-column>
                            <el-table-column prop="plantilla_certificado" label="Plantilla Certificado" width="360"></el-table-column>
                            <el-table-column label="Actions" width="100">
                                <template v-slot="scope">
                                    <v-btn color="info" small outlined @click="unidadesOperativasEdit(scope.row)" title="Editar" v-if="permisos[3]">
                                    <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                            </el-table-column>
                        </el-table>
                        </div>
                        <Pagination :pagination="pagination" @setCambiarPagina="setCambiarPagina" v-if="pagination.total"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>



</template>

<script>

import Auth from "@/services/auth";
import Pagination from"@/pages/Components/Pagination.vue";
import { Table as ElTable, TableColumn as ElTableColumn } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

export default {
    name: 'AdministracionUnidadesOperativasLista',
    components: { ElTable, ElTableColumn, Pagination },
    data() {
        return {
            /*
            tableData: [
            ],
            */
            permisos: [],
            unidadesOperativas: [],
            search: "",
            pagination: {},
            pageActual: 1,
            rowsPage: this.$store.state.arrayRowsPages[0],
            loading: false,
            tableHeaders: [
                {
                    text: 'Institución',
                    value: 'institucion',
                    align: 'left',
                },
                {
                    text: 'Unidad Operativa',
                    value: 'unidad_operativa',
                    align: 'left',
                },
                {
                    text: 'Modo de formación',
                    value: 'modo_formacion',
                    align: 'left',
                },
                {
                    text: 'Nota Mínima',
                    value: 'nota_minima_aprobar',
                    align: 'right',
                },
                {
                    text: 'Director Ejecutivo',
                    value: 'director_ejecutivo',
                    align: 'right',
                },
                {
                    text: 'Jefe Departamento',
                    value: 'jefe_departamento',
                    align: 'right',
                },
                {
                    text: 'Plantilla Certificado',
                    value: 'plantilla_certificado',
                    align: 'right',
                },
                {
                    text: 'Acciones',
                    align: 'center',
                },
            ],
        }
    },

    methods: {
        unidadOperativaNuevo() {
            this.$router.push({name: 'AdministracionUnidadesOperativasNuevo'});
        },
        unidadesOperativasBuscar() {
            this.pageActual = 1;
            this.unidadesOperativasGet();
        },
        unidadesOperativasGet() {
            this.loading = true;
            let params = {busqueda: this.search, page: this.pageActual, rowsPage: this.rowsPage};
            window.axios.get('/unidades_operativas', {params}).then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    //this.unidadesOperativas = data.unidadesOperativas.data;
                    // Lets convert to tree table format
                    //this.tableData = this.formatData(data.unidadesOperativas.data);
                    this.unidadesOperativas = this.formatData(data.unidadesOperativas.data);
                    this.pagination = data.pagination;
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo listar datos.");
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        unidadesOperativasEdit(unidadOperativa) {
            if (typeof unidadOperativa == "object") {
                let token = window.btoa(unidadOperativa.unidad_operativa_id);
                this.$router.push({name: 'AdministracionUnidadesOperativasEditar', query: {token}});
            } else {
                this.$toast.error("Parametro no valido.");
            }
        },
        setCambiarPagina(data) {
            this.pageActual = data.page;
            this.rowsPage = data.rowsPage;
            this.unidadesOperativasGet();
        },
        async fetchData() {
            try {
                //const response = await fetch('path-to-your-api-or-processed-data')
                //const data = await response.json()
                /*
                const data = [
                    { "unidad_operativa_id": 1, "unidad_operativa_padre_id": null, "level": 1, "path": "001" },
                    { "unidad_operativa_id": 2, "unidad_operativa_padre_id": 1, "level": 2, "path": "001-002" },
                    { "unidad_operativa_id": 3, "unidad_operativa_padre_id": 1, "level": 2, "path": "001-003" },
                    { "unidad_operativa_id": 4, "unidad_operativa_padre_id": 2, "level": 3, "path": "001-002-004" }
                ];
                */
                /*
                const data = [
                    {
                        "level": 1,
                        "path": "001",
                        "unidad_operativa_id": 1,
                        "unidad_operativa_padre_id": null,
                        "institucion": "INFOP",
                        "unidad_operativa": "Formación a Distancia",
                        "modo_formacion": "Complementación a Distancia (Virtual)",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla Generica Formacion a Distancia 2024"
                    },
                    {
                        "level": 2,
                        "path": "001-003",
                        "unidad_operativa_id": 3,
                        "unidad_operativa_padre_id": 1.0,
                        "institucion": "INFOP - HP-LIFE",
                        "unidad_operativa": "Formación a Distancia - HP LIFE",
                        "modo_formacion": "Complementación a Distancia (Virtual)",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla HP Life 2024"
                    },
                    {
                        "level": 2,
                        "path": "001-004",
                        "unidad_operativa_id": 4,
                        "unidad_operativa_padre_id": 1.0,
                        "institucion": "INFOP",
                        "unidad_operativa": "Formación a Distancia - ORACLE",
                        "modo_formacion": "Complementación a Distancia (Virtual)",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla Generica Formacion a Distancia 2024"
                    },
                    {
                        "level": 2,
                        "path": "001-005",
                        "unidad_operativa_id": 5,
                        "unidad_operativa_padre_id": 1.0,
                        "institucion": "INFOP - CISCO",
                        "unidad_operativa": "Formación a Distancia - CISCO",
                        "modo_formacion": "Complementación a Distancia (Virtual - Presencial)",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla Cisco 2024"
                    },
                    {
                        "level": 2,
                        "path": "001-006",
                        "unidad_operativa_id": 6,
                        "unidad_operativa_padre_id": 1.0,
                        "institucion": "INFOP-NEO",
                        "unidad_operativa": "Formación a Distancia - NEO",
                        "modo_formacion": "Complementación a Distancia (Virtual)",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla Neo 2024"
                    },
                    {
                        "level": 2,
                        "path": "001-007",
                        "unidad_operativa_id": 7,
                        "unidad_operativa_padre_id": 1.0,
                        "institucion": "INFOP-EIE",
                        "unidad_operativa": "Formación a Distancia - EIE",
                        "modo_formacion": "Complementación a Distancia (Virtual)",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla EIE 2024"
                    },
                    {
                        "level": 1,
                        "path": "002",
                        "unidad_operativa_id": 2,
                        "unidad_operativa_padre_id": null,
                        "institucion": "INFOP",
                        "unidad_operativa": "Departamento de Formación a Distancia",
                        "modo_formacion": "Complementación a Distancia (Virtual)",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla Generica Formacion a Distancia 2024"
                    },
                    {
                        "level": 1,
                        "path": "008",
                        "unidad_operativa_id": 8,
                        "unidad_operativa_padre_id": null,
                        "institucion": "INFOP - PRESENCIAL",
                        "unidad_operativa": "Formación a Distancia (E-learning)",
                        "modo_formacion": "Complementación Presencial",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Carlos Francisco Suazo Calderón",
                        "jefe_departamento": "Jefry Joel Alvarado Varela",
                        "descripcion": "Plantilla Generica Formacion a Distancia 2024"
                    },
                    {
                        "level": 1,
                        "path": "009",
                        "unidad_operativa_id": 9,
                        "unidad_operativa_padre_id": null,
                        "institucion": "INFOP",
                        "unidad_operativa": "Acciones Formativas Externas",
                        "modo_formacion": "Complementación",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": null,
                        "jefe_departamento": null,
                        "descripcion": null
                    },
                    {
                        "level": 1,
                        "path": "010",
                        "unidad_operativa_id": 10,
                        "unidad_operativa_padre_id": null,
                        "institucion": "INFOP",
                        "unidad_operativa": "Formación Hibrida",
                        "modo_formacion": "Distancia - Videoconferencia",
                        "nota_minima_aprobar": 70,
                        "director_ejecutivo": "Jefry Joel Alvarado Varela",
                        "jefe_departamento": "Marbyn Molina",
                        "descripcion": "Curso  prueba"
                    }
                ];
                this.tableData = this.formatData(data)
                */
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        },
        formatData(data) {
            const map = {}
            data.forEach(item => {
                item.children = []
                map[item.unidad_operativa_id] = item
            })
            const treeData = []
            data.forEach(item => {
                if (item.unidad_operativa_padre_id) {
                    map[item.unidad_operativa_padre_id].children.push(item)
                } else {
                    treeData.push(item)
                }
            })
            return treeData
        }
    },
    created() {
        Auth.checkPermisos(this.$route.path).then((res) => {
            this.permisos = res;
            this.unidadesOperativasGet();
        });
        //this.fetchData();
    }
}
</script>

<style>

</style>
